import { Sizes } from '../style/themes/theme.types'
import { mTheme } from '../style/themes'
import { css } from '@emotion/react'

interface BoxSize {
  x?: keyof Sizes | 'none'
  y?: keyof Sizes | 'none'
}

let { size } = mTheme

const style: StyleWithOptions<BoxSize> = ({ x, y }) => {
  let paddingX = size.S
  let paddingY = size.S

  if (x === 'none') {
    paddingX = 0
  } else if (x) {
    paddingX = size[x]
  }

  if (y === 'none') {
    paddingY = 0
  } else if (y) {
    paddingY = size[y]
  }

  return css({
    width: paddingX,
    height: paddingY,
  })
}

export const Spacer: React.FC<BoxSize> = (props) => {
  return <div css={style(props)} />
}
